import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Collapse, Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import * as actionTypes from '../../../Containers/Entries/Store/actionTypes';
import { getVisitorData } from '../../../Containers/Entries/Store/actions';
import actions from '../../../Containers/Zones/Store/actions';
import PillButton from '../../Buttons/PillButton';
import StandardTextfield from '../../Input/TextField';
import BlacklistedCandidatesTable from './BlacklistedCandidatesTable';
import styles from './styles';

export const candidateFields = {
    address: "Address",
    mobile: "Mobile Number",
    email: "Email",
    department: "Department",
    thesisProfessor: "Supervisor",
    thesisProfessorContact: "Supervisor Contact",
    permanentAddress: "Permanent Address",
    parentContact: "Parent's Contact"
  }


function BlacklistModal({open, setOpen, zoneId}) {
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();    
    const {visitorData} = useSelector(s => s.entries)
    const [expanded, setExpanded] = useState({
        addNew: false,
        list: true,
    })
    const [rollNumber, setRollNumber] = useState("");
    const [isLoading, setIsLoading] = useState({
        getCandidate: false,
        showCandidate: false,
        blacklistCandidate: false,
    })

  const clearCandidateData = useCallback(() => {
    dispatch({
        type: actionTypes.ENTRIES_UPDATE_STATE,
        data: { visitorData: null },
        });
  }, [dispatch])

  useEffect(()=>{
    if(visitorData !== null) clearCandidateData();
  },[dispatch, clearCandidateData, rollNumber])

  const handleClose = () => setOpen(false)

  const handleToggleAccordion = (toExpandKey, toContractKey) => {
    clearCandidateData()
    if(!expanded[toExpandKey] && expanded[toContractKey]){
       return setExpanded(prev => ({...prev, [toExpandKey]: !prev[toExpandKey], [toContractKey]: !prev[toContractKey]}))
    }
    setExpanded(prev => ({...prev, [toExpandKey]: !prev[toExpandKey]}))
  }

  const handleChange = (name, value, setter) => {
    if(name === "rollNumber") {
        const isNumber = /^-?\d*\.?\d*$/;
        if(!isNumber.test(value)) return;
    }
    setter(value);
  }

  const getCandidate = () => {
        if(!rollNumber) return;
        setIsLoading(prev => ({...prev, getCandidate: true}))
        dispatch(getVisitorData(rollNumber)).then(res => {
            setIsLoading(prev => ({...prev, getCandidate: false}))
        })
  }

  const handleBlacklistCandidate = () => {
    if(isLoading.blacklistCandidate) return;
    const reqBody = {
        candidates: [
          {
            identifier: rollNumber,
            name: visitorData?.name
          }
        ]
      }
    setIsLoading(prev => ({...prev, blacklistCandidate: true}))
    dispatch(actions.blacklistCandidate(zoneId, reqBody)).then((res) => {
        setIsLoading(prev => ({...prev, blacklistCandidate: false}))
        if(res) {
            setRollNumber("");
            clearCandidateData();
            enqueueSnackbar("This candidate has been blacklisted.", {
                variant: "success"
            })
            dispatch(actions.fetchBlacklistedCandidates({seed: 0, count: 10, id: zoneId}))
        }
    })
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    maxWidth
  >
    <Box sx={styles.topRow}>
    <Typography variant="h2" color="primary">{"Blacklist"}</Typography>
        <IconButton onClick={handleClose} size="small">
          <CloseIcon sx={{ height: "1.8rem", width: "1.8rem"}}/>
        </IconButton>
    </Box>
    <DialogContent sx={{...styles.dialogContent}}>
    <Box sx={styles.outerBox}>
        <Button 
        sx={{width: "100%", padding: "1.25rem 1.4rem"}}
        onClick={() => handleToggleAccordion("addNew", "list")}
        >
            <Box sx={styles.accordionTopRow}>
                <Typography variant="h4" color="text.heading">Add New Blacklist</Typography>
                {expanded.addNew ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
        </Button>
        <TransitionGroup>
        {
            expanded.addNew && 
                <Collapse>
                    <Box sx={styles.innerBox}>
                        <StandardTextfield 
                            label="Enter Roll Number"
                            sx={{width: "100%"}}
                            endIcon={<ArrowForwardIcon style={{color: !rollNumber ? "lightgrey" : "#D5B274"}} />}
                            endIconOnClick={getCandidate}
                            value={rollNumber}
                            onChange={(e) => handleChange("rollNumber", e.target.value, setRollNumber)}
                            onKeyDown={(e) => {
                                if(e.key === "Enter") getCandidate()
                            }}
                        />
                        {
                            visitorData && Object.keys(visitorData)?.length > 0 && 
                            <Box sx={styles.candidateFieldsBox}>
                            {
                                isLoading.getCandidate || isLoading.blacklistCandidate ?
                                    <Box sx={{display: "grid", placeItems: "center", height: "100%"}}>
                                    <CircularProgress /> 
                                    </Box> :    
                                <Box sx={{display: "flex", flexFlow: "column", rowGap: "2rem"}}>
                                    <Box sx={{display: "flex", columnGap: "1.3rem", alignItems: "center"}}>
                                        {
                                            visitorData?.photo &&
                                            <img
                                                src={`data:image/png;base64,${visitorData?.photo}`}
                                                alt={"candidate"}
                                                style={styles.candidateImg}
                                            />
                                        }
                                        <Box sx={{display: "flex", flexFlow: "column", rowGap: "0.75rem"}}>
                                            <Typography variant="h3">{visitorData?.name || "-"}</Typography>
                                            <Typography variant="subtitle1" sx={{fontWeight: "400"}}>
                                                {visitorData?.rollNumber || "-"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Grid container rowSpacing={1.6} columnGap={1.25}>
                                    {Object.keys(candidateFields).map(key => 
                                        <Grid item xs={3.5}>
                                            <Box sx={{display: "flex", flexFlow: "column", rowGap: "0.5rem"}}>
                                                <Typography variant="caption" sx={{fontWeight: "700"}}>
                                                {candidateFields[key]}
                                                </Typography>
                                                <Typography variant="caption" sx={{wordBreak: "break-word"}}>{visitorData?.[key] || "-"}</Typography>
                                            </Box>
                                        </Grid>
                                    )}
                                    </Grid>
                                </Box>
                            }
                            </Box>
                        }
                        <Box sx={styles.buttonsRow}>
                            <Button
                                sx={{typography: "h4", color: "primary.main"}}
                                onClick={() => handleToggleAccordion("addNew", "list")}
                            >
                            Cancel
                            </Button>
                            <PillButton
                                text={"Blacklist Candidate"}
                                onClick={handleBlacklistCandidate}
                                disabled={!rollNumber || !visitorData?.name}
                            />
                        </Box>
                    </Box>
                </Collapse>
        }
            </TransitionGroup>
    </Box>
    <Box sx={styles.outerBox}>
        <Button 
        sx={{width: "100%", padding: "1.25rem 1.4rem"}}
        onClick={() => handleToggleAccordion("list", "addNew")}
        >
            <Box sx={styles.accordionTopRow}>
                <Typography variant="h4" color="text.heading">Blacklisted Candidates</Typography>
                {expanded.list ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
        </Button>
    <TransitionGroup>
    {
        expanded.list && 
            <Collapse>
               <BlacklistedCandidatesTable 
                zoneId={zoneId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
               />
              </Collapse>
    }
        </TransitionGroup>
    </Box>
    
    </DialogContent>
  </Dialog>
  )
}

export default  BlacklistModal