import { Fragment, useEffect, useCallback } from "react";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from "react-router";
import styles from "./AppStyles.js";
import Sidebar from "./Components/Common/sidebar/index";
import Login from "./Containers/Auth/Login/index.js";
import Zones from "./Containers/Zones";
import AddZone from "./Containers/Zones/zoneInfo";
import DeviceManagement from "./Containers/Device/index.js";
import Dashboard from "./Containers/Dashboard";
import Entries from "./Containers/Entries/index.js";
import EditProfile from "./Containers/EditProfile/index.js";
import axios from "axios";
import { axiosCatchError, axiosRequestInterceptor, axiosResponseInterceptor } from "./Axios.js";
import * as AlertActionTypes from "./Containers/Alert/store/actionTypes";
import { startRefreshAccessTokenAtRegularInterval } from "./Utility/tokenUtils.js";
import * as actionTypes from "./Containers/Auth/store/actionTypes";
import { listenEvents } from "./Services/SSEService.js";
import api from "./api.js";
import { Close } from "@mui/icons-material";
import { userLevelToRoutesMap } from "./Config/roleBasedAccess/routeLevel.js";

const App = ({ classes }) => {
  const theme = useTheme();
  const smallerThanSMScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const smallerThanMDScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const auth = useSelector(state => state.auth);
  const {allowedRoutes, token} = auth ?? {};
  const isAuthenticated = token ? true : false;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showErrorSnackbar = useCallback((errorMessage) => {
    enqueueSnackbar(errorMessage ? errorMessage : "Something went wrong.", {
      variant: "error",
      preventDuplicate: true
    });
  }, [enqueueSnackbar]);

  const setLoading = useCallback((load) => {
    dispatch({
      type: AlertActionTypes.SET_LOADING,
      data: load,
    });
  }, [dispatch]);


  useEffect(() => {
    const handleRefresh = async () => {
      const response = await startRefreshAccessTokenAtRegularInterval();
      if (!response) {
        console.log("dispatching");
        dispatch({
          type: actionTypes.AUTH_LOGOUT,
        });
      }
    }
    // if (isAuthenticated) {
    //   listenEvents(`${api.notifications.subscribe}`, { enqueueSnackbar });
    //   if (!location.pathname.includes("login")) {
    //     handleRefresh()
    //   }
    // }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    window.snackbar = (variant, msg) => {
      console.log(msg);
      if (msg) {
        console.info('in snackbar')
        return enqueueSnackbar(
          msg?.slice(0, 1).toUpperCase().concat(msg?.slice(1)),
          {
            variant,
            style: { overflowWrap: "anywhere", zIndex: "9999999 !important" },
            className: "snackbar",
            action: (key) => (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => closeSnackbar(key)}
              >
                <Close fontSize="small" />
              </IconButton>
            ),
          }
        );
      }
    };
    //axios interceptors
    const requestInterceptor = axios.interceptors.request.use((request) =>
      axiosRequestInterceptor(request, setLoading)
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response) =>
        axiosResponseInterceptor(
          response,
          setLoading,
          history,
          showErrorSnackbar,
          dispatch
        ),
      (error) => axiosCatchError(error, setLoading, history, showErrorSnackbar, dispatch)
    );
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch, history, setLoading, showErrorSnackbar]);

  if (
    isAuthenticated &&
    localStorage.getItem("userLevel") !== undefined &&
    localStorage.getItem("userLevel") !== null
  ) {
    return (
      <Box
        sx={{
          padding: smallerThanSMScreen ? ".75rem" : "1.5rem",
          height: "100%",
          display: "flex",
          fontFamily: "Lato",
          flexDirection: smallerThanMDScreen ? "column" : "row",
          columnGap: smallerThanMDScreen ? "0" : "1rem",
          rowGap: smallerThanMDScreen ? "1rem" : 0,
        }}
      >
        <Box style={{ width: smallerThanMDScreen ? "inherit" : "20%" }}>
          <Sidebar />
        </Box>
        <Box
          className={classes.main}
          sx={{
            width: smallerThanMDScreen ? "inherit" : "80%",
            height: "100%",
          }}
        >
          <Fragment>
          {
            Object.keys(userLevelToRoutesMap).map(userLevel => {
              const routes = userLevelToRoutesMap[userLevel];
              return localStorage.getItem("userLevel") === userLevel &&
              <Switch key={userLevel}>
                {
                  routes.map(route => {
                    return allowedRoutes?.includes(route.path) && (
                      <Route
                          key={route.path}
                          path={route.path}
                          component={route.component}
                          exact={route?.exact ?? false}
                      />
                     )
                    }
                  )
                }
                {
                 localStorage.getItem("userLevel") === "user" &&
                <Route path="*">
                    <Redirect to="/entries" />
                </Route>
                }
              </Switch>
            })
          }
          </Fragment>
        </Box>
      </Box>
    );
  }

  return (
    <Switch>
      <Route path="/user/reset-password" component={() => <Login resetPassword={1} />} />
      <Route path="/user/login" component={Login} />
      <Route path="*">
        <Redirect to="/user/login" />
      </Route>
    </Switch>
  );
};

export default withRouter(withStyles(styles)(App));
