import { Box, Card, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Filter from './Filter';
import { getEntriesList } from './Store/actions';
import TabularDetails from './TabularDetails';

const initialInput = {
  zone: null,
  typeOfVisitor: [],
  state: null,
  from: null,
  to: null,
  purpose:[]
}

export default function Entries() {
  const theme = useTheme();
  const dispatch = useDispatch()
  const [filterValues, setFilterValue] = React.useState(initialInput);
  // const [input, setInput] = React.useState(initialInput)
  const [tableData, setTableData] = React.useState();
  const [page, setPage] = React.useState(1)
  const smallerThanMDScreen = useMediaQuery('(min-width: 700px)');
  // use useCallBack
  const fetchData = React.useCallback(
    async (seed = 0, limit = 20, searchVal, filters) => {
      let appliedFilters = filters ?? filterValues
      let params = { ...appliedFilters }
      if(appliedFilters?.zone?._id) params.zone = appliedFilters?.zone?._id

      if (typeof searchVal === 'string') {
        params.search = searchVal;
      } else if (searchVal && Object.keys(searchVal).length > 0) {
        params = { ...params, ...searchVal }
      } else if(params?.search?.length > 0 && !searchVal){
        delete params.search
      }
      
      try {
        setTableData(params);
        setPage(seed)
        dispatch(getEntriesList(seed, limit, params))
      }
      catch (err) {
        console.error(err);
      }
    }, TabularDetails
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterValues]
  );

  return (

    <Box
      sx={{ display: 'flex', flexDirection: "column", height: smallerThanMDScreen ? '100%':'fit-content', flexWrap: 'nowrap', rowGap: '10px', justifyContent:"space-between", alignItems:"center" }}
    >
      {/* <Card variant="outlined" sx={{
        width: '100%', height: 'fit-content', flex: '0 1 auto', padding: '1em', borderRadius: '8px',
        border: '1px solid #DADADA', overflow: 'inherit'
      }}><Filter 
        fetchData={fetchData}
        input={filterValues}
        setFilterValue={setFilterValue}
        initialInput={initialInput}
        /></Card> */}

      <Card variant="outlined" sx={{
        width: '100%', height: '100%', flex: '1 1 auto', padding: '1em', borderRadius: '8px',
        border: '1px solid #DADADA',marginBottom:'.2rem'
      }}><TabularDetails
          input={tableData}
          fetchData={fetchData}
          page={page}
          /></Card>
    </Box>
  )
}
