import { MeetingRoom, MessageSharp, Settings, Support } from "@mui/icons-material";
import { ReactComponent as DesignsIcon } from "../../Assets/svg/sidebar-designs.svg";


export const sidebarData = [

  {
    label: "Guest List",
    link: "/entries",
    icon: <MeetingRoom />
  },
  {
    label: "General Settings",
    link: "/home",
    icon: <Settings />
  },
  {
    label: "Support",
    link: "/support",
    icon: <Support />
  }
];
