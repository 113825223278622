import React, {useMemo, useEffect, useState, useRef} from 'react'
import { Box, Button, Divider, IconButton, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockResetOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import usePagination from '../../Helpers/hooks/usePagination';
import { useDispatch, useSelector } from 'react-redux';
import PaginationBottomRow from '../../Components/PaginationBottomRow';
import { useSnackbar } from 'notistack';
import { deleteUser, sendResetPasswordEmail } from './store/actions';
import WarningModal from '../../Components/WarningModal';
import PatchedTooltip from '../../Components/PatchedTooltip';

const tableColumns = ["Name", "Email Address", "Role", "Action"]

function UsersTable({isLoading, states, functions}) {
    const {
        page, setPage,
        rowsPerPage, setRowsPerPage,
        rowCounts,
    } = states;
    const {
        fetchUsers,
        handlePageChange,
    } = functions;
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar()
    const {componentAccessMap} = useSelector(s => s.auth);
    const userManagementRedux = useSelector(s => s.userManagement.users)
    const {list: users, totalCount: totalUsers} = userManagementRedux;
    const [showDeleteUserWarning, setShowDeleteUserWarning] = useState(false);
    const selectedUser = useRef(null);

    const totalPageCount = Math.ceil(totalUsers / rowsPerPage);

    const pagesList = useMemo(()=>{
        const arr = [];
        const totalPageCount = Math.ceil(totalUsers / rowsPerPage);
        for (let i = 1; i <= totalPageCount; i ++) {
          arr[i-1] = i;
        }
        return arr;
      }, [rowsPerPage, totalUsers,]);

      const handleBeginAction = (user, setter) => {
        const {email} = user;
        selectedUser.current = {
            email
        }
        setter(true)
      }

      const handleDeleteUser = () => {
        dispatch(deleteUser(selectedUser.current.email)).then(res => {
         setShowDeleteUserWarning(false);
         if (res === "success") {
             fetchUsers(((page - 1) * rowsPerPage), rowsPerPage);
             enqueueSnackbar("User deleted.", {variant: "success"})
         }
        })
     }
     const handleSendResetPasswordEmail = (email) => {
        dispatch(sendResetPasswordEmail(email)).then(res => {
         if (res === "success") {
             enqueueSnackbar("Reset password email sent.", {variant: "success"})
         }
        })
     }

  return (
    <Box>
         {
            showDeleteUserWarning && 
            <WarningModal 
                open={showDeleteUserWarning}
                setOpen={setShowDeleteUserWarning}
                subtitle={"Delete User"}
                caption={"Are you sure you want to delete this user?"}
                handler={handleDeleteUser}
            />
         }
         <Table
                  size="small"
                  sx={{border: "1px solid", borderColor: "border.main"}}
                  >
                  <colgroup>
                    <col style={{width: "35%"}} />
                    <col style={{width: "35%"}} />
                    <col style={{width: "15%"}} />
                    <col style={{width: "15%"}} />
                  </colgroup>
                  <TableHead>
                   <TableRow>
                    {
                        tableColumns.map(columnTitle => 
                          <TableCell
                           key={columnTitle}
                           sx={{backgroundColor: "#F4F4F4", paddingTop: "0.9rem", paddingBottom: "0.9rem"}}
                          >
                            <Typography variant="h4">{columnTitle}</Typography>
                          </TableCell>
                        )
                    }
                   </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                      isLoading ? 
                        Array(rowsPerPage).fill().map((row, index) => 
                        <TableRow
                            key={index}
                            sx={{
                                borderTop: "1px solid #DADADA"
                            }}
                            >
                            {
                              ["1", "2", "3", "4"].map(row => 
                              <TableCell
                              key={row}
                              sx={{paddingTop: "1.1rem", paddingBottom: "1.1rem"}}>
                                  <Skeleton sx={{width: "50%"}} /> 
                              </TableCell> 
                              )
                            }
                        </TableRow> 
                        ) :
                      users?.map((row, index) => {
                        const {firstName, lastName, email, roles} = row;
                          const userInfo = {
                              name: `${firstName} ${lastName}`,
                              email,
                              //mobile: "-",
                              role: roles[0] ?? "-",
                              //status: "-"
                          }
                      return (
                          <TableRow
                          key={index}
                          sx={{
                            //   backgroundColor: index % 2 === 0 ? "#FEFEFE" : "#FAFAFA",
                              borderTop: "1px solid #DADADA"
                          }}
                          >
                            <React.Fragment>
                                {
                                Object.keys(userInfo).map(key => 
                                key !== "showInfo" &&
                                <TableCell key={key}
                                sx={{paddingTop: "1.4rem", paddingBottom: "1.4rem"}}
                                >
                                    <Typography variant="subtitle1" sx={{fontWeight: "400"}}>{userInfo[key]}</Typography>
                                </TableCell>
                                )
                            }
                            <TableCell>
                            <Box sx={{display: "flex", columnGap: "0.75rem", alignItems: "center"}}>
                                {/* <IconButton>
                                    <EditIcon />
                                </IconButton> */}
                                <PatchedTooltip
                                title={!componentAccessMap?.userManagement?.deleteUser ? "You don't have permission to use this. Contact Administrator." : ""}
                                >
                                  <IconButton size="small" sx={{color: "primary.main"}}
                                  onClick={() => {
                                    if (!componentAccessMap?.userManagement?.deleteUser) return;
                                    handleBeginAction(row, setShowDeleteUserWarning)
                                  }}
                                  disabled={!componentAccessMap?.userManagement?.deleteUser}
                                  >
                                      <DeleteIcon style={{height: "1.75rem", width: "1.75rem"}} />
                                  </IconButton>
                                </PatchedTooltip>
                                <PatchedTooltip
                                  title={!componentAccessMap?.userManagement?.resetUserPassword ? "You don't have permission to use this. Contact Administrator." : ""}
                                >
                                  <IconButton size="small" sx={{color: "primary.main"}}
                                  onClick={() => {
                                    if (!componentAccessMap?.userManagement?.resetUserPassword) return;
                                    handleSendResetPasswordEmail(email)
                                  }}
                                  disabled={!componentAccessMap?.userManagement?.resetUserPassword}
                                  >
                                      <LockResetIcon style={{height: "1.75rem", width: "1.75rem"}}/>
                                  </IconButton>
                                </PatchedTooltip>
                            </Box>
                            </TableCell>
                            </React.Fragment>
                      </TableRow>
                      )
                  })}
                  </TableBody>
          </Table>
          <Box sx={{backgroundColor: "#F4F4F4", padding: "0.8rem", border: "1px solid", borderColor: "#DADADA", borderTop: "none"}}>
            <PaginationBottomRow
                page={page}
                setPage={setPage}
                pagesList={pagesList}
                rowCounts={rowCounts}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handlePageChange={handlePageChange}
                totalCount={totalPageCount}
                mode="tabular"
                noMarginTop
                size="small"
            />
          </Box>
    </Box>
  )
}

export default UsersTable