const getIncludedRoles = (role) => {
    let resource = ""
    if(role.includes("access-create")){
        return [role];
    }
    else if(role.includes("access-delete")){
        resource = role.split("access-delete_")[1];
        return [
            "access-create_" + resource,
            role,
        ]
    }
    else if(role.includes("access-edit")){
        resource = role.split("access-edit_")[1];
        return [
            "access-create_" + resource,
            "access-delete_" + resource,
            role,
        ]
    }
    else if(role.includes("access-view")){
        resource = role.split("access-view_")[1];
        return [
            "access-create_" + resource,
            "access-delete_" + resource,
            "access-edit_" + resource,
            role,
        ]
    }
    else return [role];
}


const componentToRolesMap = {
    dashboard: {
        zoneStats: [
            "access-view_zone_statistics",
            "access-view_zone"
        ],
        entriesStats: [
            "access-view_entry_statistics",
            "access-view_zone",
            "access-view_entry"
        ],
        devicesStats: [
            "access-view_device"
        ],
        assignDevices: [
            "access-view_zone",
            "access-edit_device",
        ]
    },
    entries: {
        manualSignOff: [
            "access-edit_entry",
            "access-view_zone",
            "access-view_visits_config" // view manual sign off reasons
        ],
        zonesFilter: [
            "access-view_zone"
        ],
        visitsConfigsFilters: [
            "access-view_visits_config"
        ], // view purposes, type of visitors
        candidateInfo: [
            "access-view_person"
        ],
        export: [
            "access-download_entry",
            "access-view_person"
        ]
    },
    devices: {
        addNew: [
            "access-create_device"
        ],
        assign: [
            "access-edit_device",
            "access-view_zone"
        ],
        editActions: [
            "access-edit_device",
        ] //unassign, updatePin, archive, sendMessage
    },
    zones: {
        addNew: [
            "access-create_zone"
        ],
        delete: [
            "access-delete_zone"
        ],
        editActions: [
            "access-edit_zone",
            "access-view_visits_config" //view purposes
        ], //edit zone, activate/deactivate zone, activate/deactivate checkpoint
        addOrRemoveDevice: [
            "access-edit_device",
            "access-edit_zone"
        ],
        blacklist: [
            "access-edit_zone",
            "access-view_person"
        ]
    },
    profile: {
        // visits config - purpose, visitor type, manual sign off reason
        viewVisitsConfig: [
            "access-view_visits_config"
        ],
        editVisitsConfig: [
            "access-edit_visits_config"
        ],
        deleteVisitsConfig: [
            "access-delete_visits_config"
        ],
        addVisitsConfig: [
            "access-create_visits_config"
        ],
    },
    userManagement: {
        addUser: [
            "user-create",
        ],
        deleteUser: [
            "user-delete"
        ],
        resetUserPassword: [
            "user-edit"
        ],
        addRole: [
            "roles-create"
        ],
        deleteRole: [
            "roles-delete"
        ]
    }
}

export {componentToRolesMap, getIncludedRoles};