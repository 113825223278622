import { Chart, LinearScale, Tooltip } from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { memo, useEffect, useRef } from 'react';
import { getTrimmedText } from '../../../Helpers/helpers';

Chart.register(LinearScale, Tooltip, TreemapController, TreemapElement, ChartDataLabels);
const colors = ["#ed9d1c", "#ea9000", "#4DA7CE", "#5085AB", "#5581A9", "#4DB5F0", "#328ABC", "#2EA4BE", "#408CA5", "#59C3E4"]
const lighterShades = ["#4DB5F0", "#328ABC", "#2EA4BE", "#408CA5", "#59C3E4"]

function Treemap(props) {
  const {tree, keys: {labelKey, valueKey}, handlers: { onClick }} = props
    const canvas = useRef(null);
    
    useEffect(() => {
      const chart = new Chart(canvas.current, {
        type: "treemap",
        options:{
        onClick,
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                formatter: function(value, context) {
                    const width = context?.dataset?.data[context?.dataIndex]?.w
                    const height = context?.dataset?.data[context?.dataIndex]?.h
                    if(width < 35 || height < 30) {
                      return "..."
                    }
                    if(width < 55) {
                       return value.g ? getTrimmedText(value.g, 1) : ""
                    }
                    return value.g
                },
                color: '#fff',
                font: {
                  size: 10
                }
            },
        },
        },
        data: {
          datasets: [
            {
              tree: tree,
              borderColor: "#DADADA",
              key: valueKey,
              label: labelKey,
              groups: [labelKey],
              borderWidth: 1,
              borderRadius: 6,
              backgroundColor: (ctx)=> {
                if(ctx.dataIndex > 9) {
                    if(ctx.dataIndex % 3 === 0) return lighterShades[4]
                    if(ctx.dataIndex % 5 === 0) return lighterShades[3]
                    if(ctx.dataIndex % 9 === 0) return lighterShades[2]
                    if(ctx.dataIndex % 2 === 0) return lighterShades[1]
                    return lighterShades[0]
                }
                else return colors[ctx.dataIndex]
            },
              spacing: 3,
            },
          ]
        }
      });
      return () => chart.destroy();
    }, [canvas, tree, labelKey, valueKey, onClick]);
    return (
      <div>
        <canvas ref={canvas} />
      </div>
    );
  }

  export default memo(Treemap)