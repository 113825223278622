
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { QRCodeSVG } from 'qrcode.react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from '../../Components/CustomDialog';
import PaginatedTable from '../../Components/PaginatedTable';
import { camelCaseToString, displayTime, getTimeAgo } from '../../Helpers/helpers';
import { states } from '../../Helpers/utils';
import CheckIn from './CheckIn';
import * as actionTypes from "./Store/actionTypes";
import { checkInVisitor, checkOutVisitor } from './Store/actions';
import VisitorCard from './VisitorCard';


const getStatus = (state) => {

  switch (state) {
    case states.CHECKEDIN: return 'dot-green';
    case states.CHECKEDOUT: return 'dot-grey';
    default: return 'dot-yellow';
  }
}


export default function TabularDetails({ fetchData, page }) {
  const dispatch = useDispatch()

  const webCamCaptureRef = React.useRef(null);
  const verifyButtonRef = React.useRef(null);
  const [image, setImage] = React.useState("")
  const [status, setStatus] = React.useState("")

  const [openCheckInDialog, setOpenCheckInDialog] = React.useState(false)
  const [qrCode, setQrCode] = React.useState(false)
  const [list, setList] = React.useState([])
  const [checkinInProgress, setCheckinInProgress] = React.useState(false)

  const entries = useSelector(x => x.entries.entriesData)
  // const visitorData = useSelector(x => x.entries.visitorData)

  //more details
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedEntryId, setSelectedEntryId] = React.useState('')
  const open = Boolean(anchorEl);


  const handleMoreClick = React.useCallback(
    (event, id) => {
      setAnchorEl(event.currentTarget);
      // dispatch(getVisitorData(id))
    },
    [dispatch],
  );


  const handleMoreClose = () => {
    dispatch({
      type: actionTypes.ENTRIES_UPDATE_STATE,
      data: { visitorData: null },
    });
    setAnchorEl(null);
  };



  React.useEffect(() => {
    let a = [], b = [], c = [];

    entries?.list?.forEach(element => {
      if (element.state === states.PRECHECKIN) {
        return a.push(element)
      } else if (element.state === states.CHECKEDIN) {
        return b.push(element)
      } else {
        return c.push(element)
      }
    });

    setList([...a, ...b, ...c])
  }, [entries])
  // console.log('entries', entries, visitorData, open)

  return (
    <Box sx={{ height: "100%", width: '100%' }}>
      <PaginatedTable
        tablesRedux="ENTRIES"
        title="Guest List"
        cellPadding={".6rem"}
        handler={fetchData}
        searchHandler={fetchData}
        data={React.useMemo(
          () =>
            list?.map((entry, index) => {

              // let name;
              // let email;
              // let mobile;
              // if (cred.candidate) {
              //   name = cred.candidate.name;
              //   email = cred.candidate.email;
              //   mobile = cred.candidate.mobile;
              // }
              return {
                name: entry.name ?? "-",
                identifier: entry.identifier ?? "-",
                // hostName: (entry.purpose == "Meet a Friend") ? entry.host.name : "-",
                // hostAddress: (entry.purpose == "Meet a Friend") ? entry.host.address : "-",
                // email: entry.email,
                // mobile: entry.mobile,
                arrival: entry?.in?.time ? (<Tooltip arrow
                  title={getTimeAgo(entry.in.time)}>
                  <span>{displayTime(entry.in.time)}
                  </span></Tooltip>) : "-",
                departure: entry?.out?.time ? (<Tooltip arrow
                  title={getTimeAgo(entry.out.time)}>
                  <span>{displayTime(entry.out.time)}
                  </span></Tooltip>) : "-",
                state: (<Typography noWrap variant='subtitle1' sx={{
                  textAlign: 'center', display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                  <span className={getStatus(entry.state)}> </span>
                  &nbsp;&nbsp;{camelCaseToString(entry.state)}</Typography>),
                actions: (<Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenCheckInDialog(entry)
                  }}
                  sx={{ margin: '5px' }}
                // disabled={!componentAccessMap?.entries?.manualSignOff}
                >View
                </Button>),
                // actions1: (
                //   <Grid container display="flex"
                //     direction="row"
                //     justifyContent={entry.state === states.CHECKEDOUT ? "flex-end" : "space-between"}
                //     alignItems="center"
                //     sx={{ flexWrap: 'nowrap' }}>
                //     {/* <Tooltip arrow title={camelCaseToString(entry.state)}>
                //       <span className={getStatus(entry.state)}></span>
                //     </Tooltip> */}
                //     {entry.state === states.PRECHECKIN ?

                //       <Button
                //         variant="contained"
                //         onClick={() => {
                //           // if (!componentAccessMap?.entries?.manualSignOff) return;
                //           setOpenCheckInDialog(entry)
                //         }}
                //         sx={{ margin: '5px' }}
                //       // disabled={!componentAccessMap?.entries?.manualSignOff}
                //       >{camelCaseToString(states.CHECKEDIN)}</Button>
                //       : entry.state === states.CHECKEDIN ?
                //         <Grid>
                //           <Button
                //             variant="contained"
                //             color="secondary"
                //             onClick={() => {
                //               // if (!componentAccessMap?.entries?.manualSignOff) return;
                //               checkInVisitor(entry._id).then(res => {
                //                 if (!res.error) {
                //                   setQrCode(res.data)
                //                 }
                //               })

                //             }}
                //             sx={{ margin: '5px' }}
                //           // disabled={!componentAccessMap?.entries?.manualSignOff}
                //           >Get Room Key
                //           </Button><Button
                //             variant="contained"
                //             color="warning"
                //             onClick={() => {
                //               // if (!componentAccessMap?.entries?.manualSignOff) return;
                //               checkOutVisitor(entry._id).then(() => {
                //                 fetchData()
                //               })
                //             }}
                //             sx={{ margin: '5px' }}
                //           // disabled={!componentAccessMap?.entries?.manualSignOff}
                //           >{camelCaseToString(states.CHECKEDOUT)}
                //           </Button>
                //         </Grid> : null}
                //     {/* <PatchedTooltip
                //       title={!componentAccessMap?.entries?.candidateInfo ? "You don't have permission to use this. Contact Administrator" : ""}
                //     >
                //       <IconButton sx={{ padding: "0" }}
                //         disabled={!componentAccessMap?.entries?.candidateInfo}
                //         onClick={(e) => {
                //           if (!componentAccessMap?.entries?.candidateInfo) return;
                //           setSelectedEntryId(entry?._id)
                //           handleMoreClick(e, entry?.identifier)
                //         }}
                //       >
                //         <MoreVert />
                //       </IconButton>
                //     </PatchedTooltip> */}
                //   </Grid>
                // ),

              };
            }),
          [list, fetchData]
        )}
        totalCount={entries?.total ?? 0}
        columns={[
          { name: "Guest Name", sort: 'name' },
          { name: "Identifier", sort: 'identifier' },
          // { name: "Email" },
          // { name: "Mobile" },
          { name: "Checkin At", sort: 'inTime', defaultOrder: -1 },
          { name: "Checkout At", sort: 'outTime' },
          { name: "State" },
          { name: "Actions" }
        ]}
        handleRowClick={(row) => {
          // setSelectedCandidateInfo(row);
          // setOpenCandidateDetailsDrawer(true);
        }}
      // dontLoadWhen={[openCandidateDetailsDrawer]}
      // hidePagination
      // showMoreHandler={
      //   credentials?.total < 5 ?
      //     null :
      //     () => {
      //       let route = `/batches/${selectedBatchId}`;
      //       if (selectedBatchId === "singleIssuance") {
      //         route = "/issuanceHistory?singleIssuancesOnly=true";
      //       }
      //       history.push(route);
      //     }
      // }
      />

      {/* {anchorEl && <VisitorCard
        entryId={selectedEntryId}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleMoreClose} />} */}


      {/* <CustomDialog
        fullWidth
        maxWidth="lg"
        title="Check In"
        open={Boolean(openCheckInDialog?.identifier)}
        setOpen={setOpenCheckInDialog}
      ><CheckIn
          imageToMatchWith={openCheckInDialog?.data?.preCheckinImageUrl ?? "-"}
          setOpenCheckInDialog={setOpenCheckInDialog}
          id={openCheckInDialog._id}
          onSuccess={() => {
            fetchData()
          }}
        /></CustomDialog> */}


      <CustomDialog noHeader
        fullWidth
        maxWidth="md"
        title=""
        open={Boolean(openCheckInDialog?.identifier)}
        setOpen={(e) => {
          setOpenCheckInDialog(e)
          setCheckinInProgress(e)
        }}
      >
        {checkinInProgress ? <CheckIn
          imageToMatchWith={openCheckInDialog?.data?.preCheckinImageUrl ?? "-"}
          setOpenCheckInDialog={setOpenCheckInDialog}
          id={openCheckInDialog._id}
          onSuccess={() => {
            fetchData()
            setCheckinInProgress(false)
          }}
          webCamCaptureRef={webCamCaptureRef}
          verifyButtonRef={verifyButtonRef}
          image={image}
          setImage={setImage}
          status={status} setStatus={setStatus}
        /> : <VisitorCard
          entry={openCheckInDialog}
          handleClose={setOpenCheckInDialog} />}

        <Grid container direction="row" justifyContent="flex-end" alignItems="center">

          {status !== 'VERIFIED' ? <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenCheckInDialog(false)
            }}
            sx={{ margin: '5px' }}
          >Close</Button> : null}

          {(checkinInProgress && image) && (status === '') ? <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setImage('')
            }}
            sx={{ margin: '5px' }}
          >Retake</Button> : null}

          {(status === '' && openCheckInDialog.state === states.PRECHECKIN) ? <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (checkinInProgress) {
                if (image) {
                  verifyButtonRef.current.click()
                } else {
                  webCamCaptureRef.current.click()
                }
              } else {
                setCheckinInProgress(true)
              }
            }}
            sx={{ margin: '5px' }}
          >{checkinInProgress ? (image ? "Verify" : "Capture") : "Check In"}</Button> : null}

          {(openCheckInDialog.state === states.CHECKEDIN && !checkinInProgress) ? <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // if (!componentAccessMap?.entries?.manualSignOff) return;
                checkInVisitor(openCheckInDialog._id).then(res => {
                  if (!res.error) {
                    setQrCode(res.data)
                  }
                })

              }}
              sx={{ margin: '5px' }}
            // disabled={!componentAccessMap?.entries?.manualSignOff}
            >Get Room Key
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                // if (!componentAccessMap?.entries?.manualSignOff) return;
                checkOutVisitor(openCheckInDialog._id).then(() => {
                  fetchData()
                  setOpenCheckInDialog(false)
                })
              }}
              sx={{ margin: '5px' }}
            // disabled={!componentAccessMap?.entries?.manualSignOff}
            >Check Out
            </Button></> : null}

        </Grid>
      </CustomDialog>

      <CustomDialog
        fullWidth
        maxWidth="md"
        title="Get Room Key"
        open={Boolean(qrCode)}
        setOpen={setQrCode}
      >
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography variant='h6'>Please scan the QR to get room key</Typography>
          <QRCodeSVG
            value={qrCode ?? ""}
            size={window.innerHeight * 0.6}
            level={"L"}
            includeMargin={true}
            fgColor="#000000"
          />
        </Grid>
      </CustomDialog>
    </Box>
  );
}
