import loginImage from "../../../Assets/svg/brandBackgroundParticles.svg";

const styles = {
  outerBox: {
    backgroundColor: "#EFEFEF",
    // height: "100vh",
    height: {
      xs: "fit-content",
      md: "fit-content"
    },
    minHeight: "100vh",
    maxHeight: "fit-content",
    width: "100vw",
    backgroundImage: `url(${loginImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    fontFamily: "Lato",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  logoBox: {
    marginLeft: {
      // xs: "-5rem",
      bmd: "20%",
    },
    marginBottom: ".5rem",
    alignItems: "flex-start",
    marginTop: {
      xs: "10%",
      bmd: 0
    },
    display: "flex",
    flexDirection: "column",
    columnGap: {
      xl: ".5rem"
    },
    padding: "20px"
  },
  logo: {
    height: "5.2rem"
  },
  welcomeText:{
    fontFamily: "Lato",
    fontSize: {
      xs: "1.8rem",
      md: "2.2rem",
      xl: "2.8rem"
    },
    letterSpacing: "0.01em",
    textAlign: "left",
    fontWeight: '900',
    lineHeight: '2.6rem',
    fontStyle:'normal',
    color:'#ffffff'
  },
  welcomeText2: {
    fontFamily: "Lato",
    fontSize: {
      xs: "2rem",
      md: "2.4rem",
      xl: "3.4rem"
    },
    letterSpacing: "0.01em",
    textAlign: "left",
    fontWeight:'900',
    lineHeight: '2.6rem',
    fontStyle: 'normal',
    color: '#ffffff'
  },
  welcomeText3: {
    fontFamily: "Lato",
    fontSize: {
      xs: "1.4rem",
      md: "1.4rem",
      xl: "2.0rem"
    },
    letterSpacing: "0.01em",
    textAlign: "left",
    fontWeight: '600',
    lineHeight: '2.0rem',
    fontStyle: 'normal',
    color: '#ffffff'
  },
  middleOuterBox: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexGrow: "1",
    justifyContent: {
      xs: "space-evenly",
      bmd: "space-between",
    },
    flexFlow: {
      xs: "column",
      bmd: "row",
    },
    alignItems: "center",
    padding: "10px"
  },
  formBox: {
    display: "flex",
    flexFlow: "column",
    width: {
      xs: "85%",
      sm: "40rem"
    },
    marginRight: {
      bmd: "5%"
    },
    backgroundColor: "background.first",
    padding: "4rem 4rem 4rem 4rem",
    rowGap: {
      xs: "3rem",
      sm: "2.5rem"
    },
    borderRadius: "1.5rem",
    marginBottom: {
      xs: "10rem",
      bmd: 0
    },
  },
  bottomText: {
    display: "flex",
    columnGap: ".4rem",
    position: "relative",
    marginBottom: "2rem",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  trentialText: {
    fontWeight: 700,
    fontSize: "2.4rem",
    lineHeight: "20px"
  },
  headerLogin: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "1.3rem",
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginTop: "-5px",
    textAlign: "center",
    color: "#191B23",
  },
  headerDesc: {
    fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1rem",
    lineHeight: "20px",
    letterSpacing: "0.01em",
    marginTop: "-5px",
    textAlign: "center",
    color: "#191B23",
  },
  headerText: {
    textAlign: "center",
    color: "#191B23",
  },
  contactText: {
    fontWeight: "400",
    fontSize: "1.2rem",
    lineHeight: "18px",
    // marginTop: "1rem"
  },
  text: {
    fontFamily: "Lato",
    fontStyle: "normal",
    textAlign: "center",
    letterSpacing: "0.01em",
    color: "#191B23",
  }
};

export default styles