import axios from "axios";
import { baseURLs } from "../../../Axios";
import API from "../../../api";
import * as actionTypes from './actionTypes'
import { getAccessToken } from "../../../Utility/tokenUtils";

const fetchUsers = ({seed, count} = {}) => {
    const token = `Bearer ${getAccessToken()}`;
    return async (dispatch) => {
      let queryString = "";
      if (seed !== undefined && count !== undefined) queryString += `&seed=${seed}&count=${count}`;
      const response = await axios.get(
        `${baseURLs.userManagement}${API.userManagement.users}?${queryString}`,
       { headers: { Authorization: `${token}` } },
      );
      if (response?.data?.code === 0) {
        dispatch({
          type: actionTypes.FETCH_USERS,
          data: response.data.data.list,
          total: response.data.data.total
        });
        return "success";
      }
    };
}

const addRole = (reqData) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.userManagement}${API.userManagement.roles}`,
      reqData,
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return "success";
    }
  };
}

const fetchRoles = () => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.get(
      `${baseURLs.userManagement}${API.userManagement.roles}`,
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return response?.data?.data
    }
  };
}

const deleteRole = (identifier) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.delete(
      `${baseURLs.userManagement}${API.userManagement.roles}/${identifier}`,
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return "success"
    }
  };
}

const addUser = (reqData) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.userManagement}${API.userManagement.users}?portal=access-control-portal`,
      reqData,
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return "success";
    }
  };
}

const deleteUser = (email) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.delete(
      `${baseURLs.userManagement}${API.userManagement.users}/${email}`,
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return "success";
    }
  };
}

const sendResetPasswordEmail = (email) => {
  const token = `Bearer ${getAccessToken()}`;
  return async (dispatch) => {
    const response = await axios.post(
      `${baseURLs.userManagement}${API.userManagement.users}/reset-password?emailId=${email}`,
      {},
     { headers: { Authorization: `${token}` } },
    );
    if (response?.data?.code === 0) {
      return "success";
    }
  };
}

export {
    fetchUsers,
    addRole,
    fetchRoles,
    deleteRole,
    addUser,
    deleteUser,
    sendResetPasswordEmail
}