import * as actionTypes from "./actionTypes";

const initialState = {
  email: null,
  token: null,
  error: null,
  loading: false,
  authRedirectPath: "/dashboard",
  roles: [],
  allowedRoutes: [],
  componentAccessMap: {},
  registerMessage: null,
  rowsPerPage: 5
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.REGISTER_SUCCESS_MESSAGE:
      return authSuccessMessage(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.SET_ACCOUNT_DATA:
      return setAccountData(state, action);
    case actionTypes.AUTH_UPDATE_STATE:
      return updateState(state, action);
    default:
      return state;
  }
};

const setAccountData = (state, action) => {
  return {...state, name: action.name, uid: action.uid, logo: action.logo};
};

const authStart = (state, action) => {
  return { ...state, error: null, loading: true, email: action.email };
};

const authSuccess = (state, action) => {
  return {
    ...state,
    token: action.token,
    error: null,
    loading: false,
    email: action.email,
    roles: action.roles,
    allowedRoutes: action.allowedRoutes,
    componentAccessMap: action.componentAccessMap
  };
};

const authSuccessMessage = (state, action) => {
  return {
    ...state,
    registerMessage: "success",
  };
};

const authFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    registerMessage: "failed",
  };
};

const authLogout = (state, action) => {
  return initialState;
};

const updateState = (state, action) => {
  return {
    ...state,
    ...action.data
  };
};