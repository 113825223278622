import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box, Button, MenuItem, Pagination, Skeleton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useMediaQuery,
  useTheme
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from "react";
import sortIcon from '../../Assets/svg/sortIcon.svg';
import usePagination from "../../Helpers/hooks/usePagination";
import PillButton from "../Buttons/PillButton";
import StandardTextfield from "../Input/TextField";
import NoData from "../NoData";

function PaginatedTable({ handler,
  title, data, totalCount, columns, columnWidths,
  extraColumnHandlers, handleRowClick, dontLoadWhen,
  hidePagination, showMoreHandler, searchHandler,
  searchFieldWidth, cellPadding, tablesRedux }) {

  // console.info(columns,'COLUMNS')
  const theme = useTheme();
  const resetPage = useSelector(x => x.entries.resetPage)
  const smallerThanMDScreen = useMediaQuery('(min-width:700px)');
  // const isLoading = useSelector((state) => state.alert.loading);
  const isLoading = false;
  const [orders, setOrders] = useState({
    sort: 'updatedAt',
    order: -1
  })
  const { page, setPage, rowsPerPage, handlePageChange, handleChangeRows, rowCounts, searchText, handleChangeSearchText, handleCloseSearch } = usePagination({ handler, isLoading, tablesRedux, orders });

  const isActuallyLoading = () => {
    return (isLoading || (data?.length === 0 && isLoading)) &&
      (dontLoadWhen ? dontLoadWhen?.every((bool) => bool === false) : true);
  };

  // useEffect(() => {
  //   let tempOrders = {}
  //   columns.forEach(x => {
  //     if (x.sort) {
  //       tempOrders[x.name] = x.defaultOrder ?? 1
  //     }
  //   })
  //   setOrders(tempOrders)
  // }, [])

  useEffect(() => {
    setPage(1)
  }, [resetPage])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "7.5%", height: hidePagination ? "fit-content" : "100%" }}>
      <Box sx={{ height: "100%", display: "flex", flexFlow: "column", justifyContent: "flex-start" }}>
        <React.Fragment>
          {
            searchHandler &&
            <Box sx={{
              marginBottom: "1.25rem", display: "flex", justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <Typography variant="body1" sx={{
                fontSize: "var(--body-font-size-large)",
                fontFamily: "Lato",
                fontWeight: 700
              }}>{title}</Typography>
              <StandardTextfield
                value={searchText}
                onChange={handleChangeSearchText}
                startIcon={<SearchIcon sx={{
                  color: "primary.main", margin: "auto",
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} />}
                placeholder="Search"
                endIcon={searchText.length > 0 && <CloseIcon />}
                endIconOnClick={handleCloseSearch}
                size="small"
                sx={{
                  width: searchFieldWidth ?? "30%",
                  fontSize: "var(--body-font-size-mid)",
                  fontWeight: 500
                }}
              />
            </Box>
          }
          {
            !isLoading && totalCount === 0 ? (
              <NoData size={"30%"} />
            ) : (
              <TableContainer sx={{ border: "1px solid", borderColor: "border.main", borderRadius: "8px" }}>
                <Table size="small" stickyHeader>
                  {columnWidths?.length > 0 &&
                    <colgroup>
                      {columnWidths?.map((width, index) => <col key={index} style={{ width }} />)}
                    </colgroup>
                  }
                  <TableHead>
                    <TableRow>
                      {columns?.map((col, index) => (<TableCell
                        key={index}
                        align="left"
                        sx={{
                          padding: "1rem",

                        }}
                      >
                        <Typography noWrap sx={{
                          fontWeight: 700,
                          fontFamily: "Lato",
                          fontSize: "var(--body-font-size-large)",
                          color: '#000000',
                          textAlign: "center",
                          cursor: col.sort ? "pointer" : "auto"
                        }}
                          onClick={() => {
                            if (col.sort) {
                              let cur;
                              // console.info(orders,col)
                              if (orders.sort === col.sort && orders.order < 0) {
                                setOrders({ sort: col.sort, order: 1 })
                                cur = 1;
                              } else {
                                setOrders({ sort: col.sort, order: -1 })
                                cur = -1
                              };
                              // console.log('orders', orders)
                              handler(0, rowsPerPage, {
                                sort: col.sort,
                                order: cur
                              })
                            }
                          }}
                        >{col.name}
                          {col.sort ?
                            <Tooltip arrow title={(orders?.sort === col.sort && orders.order > 0)
                              ? "Descending"
                              : "Ascending"}><img
                                src={sortIcon}
                                alt="sort"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: '2px'
                                }}
                              /></Tooltip> : null} </Typography>
                      </TableCell>)


                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isActuallyLoading() ?
                      [...Array(rowsPerPage)].map((row, index) =>
                        <TableRow
                          key={index}
                        >
                          {columns.map((key) =>
                            <TableCell align="left" key={key.name} sx={{ padding: ".65rem" }}>
                              <Skeleton animation="wave" sx={{ height: "2.6vmin", width: "85%", backgroundColor: "background.fourth" }} />
                            </TableCell>)}
                        </TableRow>
                      ) :
                      data && data.map((row, index) => (
                        <TableRow
                          onClick={() => {
                            if (handleRowClick) handleRowClick(row);
                          }}
                          key={index}
                          sx={{
                            border: '1px solid #DADADA',
                            '&:last-child td, &:last-child th': { border: 0 },
                            "cursor": "pointer",
                            // "&:hover": {
                            //   backgroundColor: "#f9f9f9",
                            // },
                            "&:hover .right-arrow-user-management": {
                              opacity: 1,
                            },
                            "& td": {
                              border: 0,
                            }
                          }}
                        >
                          {Object.keys(row).map((key) =>
                            <TableCell align="center" key={key} sx={{ padding: cellPadding ?? "1rem", paddingLeft: "1rem" }}>
                              {
                                key == "status" ? row[key].toLowerCase() == "credential-issued" ?
                                  <PillButton
                                    disableHover
                                    text={"Success"}
                                    backgroundColor={"#5CC278"}
                                    padding={{ vertical: ".5rem" }}
                                    typography={"subtitle1"}
                                  /> :
                                  <PillButton
                                    text={"Pending"}
                                    backgroundColor={"#F2CB67"}
                                    disableHover
                                    padding={{ vertical: ".5rem" }}
                                    typography={"subtitle1"}
                                  /> :
                                  <Typography variant="subtitle1"
                                    sx={{
                                      fontSize: "var(--body-font-size-small)",
                                      fontWeight: 400,
                                      color: '#000000'
                                    }}>{row[key] ?? "-"}</Typography>
                              }
                            </TableCell>)}
                        </TableRow>
                      ))}
                    {
                      hidePagination && showMoreHandler && !isLoading &&
                      <TableRow>
                        <TableCell colSpan={columns.length} sx={{ textAlign: "center" }}>
                          <Button sx={{ typography: "h4", color: "primary.main", width: "fit-content" }}
                            onClick={showMoreHandler}
                          >
                            Show More
                          </Button>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
        </React.Fragment>
        {totalCount !== 0 && !hidePagination && (
          <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "2%", flexWrap: 'wrap-reverse' }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: ".5rem" }}>
              <Typography sx={{
                fontSize: "var(--body-font-size-small)",
                fontWeight: "700",
                fontFamily: "Lato",
                color: '#000000'
              }}>Rows per Page</Typography>
              <TextField
                select
                value={rowsPerPage}
                onChange={handleChangeRows}
                size="small"
                variant="outlined"
                InputProps={{
                  style: {
                    fontSize: "1.2rem",
                    borderRadius: "2rem",
                  }
                }}
              >
                {rowCounts.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Typography variant="subtitle1">
                      {option}
                    </Typography>
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Pagination
              color="primary"
              // boundaryCount={smallerThanMDScreen ? 1 : 3}
              size={"small"}
              count={Math.ceil(totalCount / rowsPerPage)}
              page={page}
              onChange={(event, value) => handlePageChange(value)}
            // sx={{ marginBottom: rowsPerPage >= 15 ? "3.5vmin" : 0, padding:'.5rem' }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default PaginatedTable;
