import { Box, Button, Card } from '@mui/material';
import { useState } from 'react';
import GoogleMaps from '../../../Components/GoogleMaps';
import Marker from "./marker";
const DeviceMap = ({ devices, generatePin, handleAssign, handleUnassign }) => {

  const isLatitude = num => isFinite(num) && Math.abs(num) <= 90;
  const isLongitude = num => isFinite(num) && Math.abs(num) <= 180;

  const deviceValidity = (device) => {
    const deviceHasCoordinates = (
      device.deviceInfo
      &&
      // device.deviceInfo?.latitude.length > 2
      isLatitude(device.deviceInfo.latitude)
      &&
      isLongitude(device.deviceInfo.longitude)
    );
    return (deviceHasCoordinates && (device.status === "assigned"));
  }

  const defaultProps = {
    // These are the coordinates for IIT Kanpur
    center: {
      lat: 26.5123,
      lng: 80.2329
    },
    zoom: 15
  };

  return (
    <div id="device-map" style={{ width: "100%", height: "100%" }}>
      <GoogleMaps
        mapOptions={defaultProps}
        markersData={devices?.filter(x => {
          if (deviceValidity(x)) {
            // console.log('device', x)
            return true
          } return false;
        }
        )?.map(z => ({ ...z, lat: Number(z.deviceInfo?.latitude), lng: Number(z.deviceInfo?.longitude) })) ?? []}
        markerRederer={(device) => {
          if (deviceValidity(device)) {
            return (
              <Marker
                key={device.id + device.deviceInfo.latitude}
                lat={device.deviceInfo.latitude}
                lng={device.deviceInfo.longitude}
                uniqueID={device._id}
                deviceID={device.deviceId}
                battery={device.deviceInfo.battery}
                checkpoint={device.checkpoint?.name}
                zone={device.zone?.name}
                pin={device.pin}
                status={device.status}
                generatePin={generatePin}
                handleAssign={handleAssign}
                handleUnassign={handleUnassign}
              />)
          } else {
            console.log('DEVICE IN MARKER', device)
            return <Button variant="contained">Device Invalid</Button>
          }
        }}
        clusterRederer={(cluster) => <ClusterComponent
          key={cluster.id}
          lat={cluster.lat}
          lng={cluster.lng}
          cluster={cluster}
          generatePin={generatePin}
          handleAssign={handleAssign}
          handleUnassign={handleUnassign} />}

      />
    </div>
  );
}

function ClusterComponent({ cluster, generatePin, handleAssign, handleUnassign }) {
  const [zIndex, setZindex] = useState(1)
  console.log('DEVICES IN CLUSTER', cluster)
  return <div
    style={{
      position: 'absolute',
      transform: 'translate(-50%, 0%)',
      zIndex: zIndex
    }}>
    <Box
      id="triangle-tip"
      sx={{
        width: 0,
        height: 0,
        borderLeft: "14px solid transparent",
        borderRight: "14px solid transparent",
        borderBottom: "14px solid #D5B274",
        position: 'relative',
        top: '0px',
      }} />
    <Card sx={{
      maxHeight: '40vh', overflowY: 'auto', width: 'max-content',
      position: 'absolute',
      transform: 'translate(-46%, 0%)'
    }}
      onMouseEnter={() => {
        setZindex(1000)
      }}
      onMouseLeave={() => {
        setZindex(1)
      }}>

      {cluster.points.map((device, index) => {
        // return <Button variant="contained"
        //   key={device.deviceInfo.latitude + '-' + index}>{device.deviceId}</Button>
        return <div key={device.id + '-marker-' + index}>

          <Marker
            uniqueID={device._id}
            deviceID={device.deviceId}
            battery={device.deviceInfo.battery}
            checkpoint={device.checkpoint?.name}
            zone={device.zone?.name}
            pin={device.pin}
            status={device.status}
            generatePin={generatePin}
            handleAssign={handleAssign}
            handleUnassign={handleUnassign}
            hideCheckpoints
            index={index}
          /></div>

      })}
    </Card></div>
}
export default DeviceMap;