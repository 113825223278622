import { Box, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Battery from '../../../Components/Battery';
import PatchedTooltip from '../../../Components/PatchedTooltip';
import Pin from '../../../Components/Pin';
import CustomSwitch from '../../../Components/Switch';

const Marker = ({
    lat, lng,
    uniqueID,
    deviceID,
    battery,
    zone,
    checkpoint,
    status,
    pin,
    generatePin,
    handleAssign,
    handleUnassign,
    hideCheckpoints = false,
    index
}) => {
    const {componentAccessMap} = useSelector(s => s.auth);
    const [zIndex, setZindex] = useState(1)
    const [isChecked, setIsChecked] = useState(status === "assigned");

    return (
        <div lat={lat} lng={lng}>
            {(hideCheckpoints) ? null : <Box
                id="triangle-tip"
                sx={{
                    width: 0,
                    height: 0,
                    borderLeft: "14px solid transparent",
                    borderRight: "14px solid transparent",
                    borderBottom: "14px solid #D5B274",
                    position: !hideCheckpoints ? 'relative' : 'inherit',
                    left: !hideCheckpoints ? '-14px' : '0'
                }} />}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    bgcolor: "white",
                    boxShadow: hideCheckpoints ? 'none' : "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                    width: "25rem",
                    p: 1.5,
                    rowGap: "1rem",
                    borderRadius: ".7rem",
                    borderTopLeftRadius: '0',
                    position: !hideCheckpoints ? 'absolute' : 'relative',
                    transform: !hideCheckpoints ? 'translate(-50%, 0%)' : 'none',
                    zIndex,
                }}

                onMouseEnter={() => {
                    setZindex(1000)
                }}
                onMouseLeave={() => {
                    setZindex(1)
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "80%", whiteSpace: "nowrap" }}
                        fontSize={15}
                        fontFamily='Lato'
                        fontWeight={'bold'}
                    >
                        {`Device ${deviceID}`}
                    </Typography>
                    <PatchedTooltip
                      title={(isChecked ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign ) ? "You don't have permission to use this. Contact Administrator" : ""}
                    >
                        <CustomSwitch
                            size="small"
                            checked={isChecked}
                            onClick={() => {
                                if ((isChecked ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign )) return;
                                if (status === "assigned") {
                                    handleUnassign({ _id: uniqueID })
                                } else {
                                    handleAssign({ _id: uniqueID })
                                }
                            }}
                            disabled={(isChecked ? !componentAccessMap?.devices?.editActions : !componentAccessMap?.devices?.assign )}
                        />
                    </PatchedTooltip>
                </Box>

                {hideCheckpoints ? null : <Box sx={{ display: "flex", columnGap: "1rem" }}>
                    <Typography
                        whiteSpace={'nowrap'} overflow="hidden" textOverflow={'ellipsis'}
                        variant="subtitle1"
                        sx={{ fontWeight: "400" }}
                        width={"40%"}
                    >
                        {zone}
                    </Typography>
                    <Typography
                        whiteSpace={'nowrap'} overflow="hidden" textOverflow={'ellipsis'}
                        variant="subtitle1"
                        sx={{ fontWeight: "400" }}
                        width={"40%"}
                    >
                        {checkpoint}
                    </Typography>
                </Box>}

                <Box display="flex" justifyContent={'space-between'}>
                    <Pin
                        info={{ pin: pin, status: status, deviceId: deviceID }}
                        generate={generatePin}
                        disableUpdatePIN={!componentAccessMap?.devices?.editActions}
                     />
                    <Battery value={battery.batteryLevel} />
                </Box>
            </Box>
            {hideCheckpoints ? <Divider variant="fullWidth" sx={{ width: '100%' }} /> : null}
        </div>
    )
}

export default Marker;