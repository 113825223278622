
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Card, Chip, CircularProgress, Divider, Grid, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import noImageAvailable from '../../Assets/img/no-image-available.jpg';
import { ReactComponent as BrandLogo } from "../../Assets/svg/brandLogo.svg";
import aadhaarLogo from "../../Assets/img/aadhaar-logo.png";
import digilockerVerified from "../../Assets/img/digilocker-verified.png";
import Nodata from '../../Components/NoData';
import { addTime, camelCaseToString, displayTime, getImageData } from '../../Helpers/helpers';

const state = { 'preCheckIn': 'warning', 'checkedIn': 'success', 'error': 'error' };


export default function VisitorCard({ handleClose, entry }) {


    const [data, setData] = useState({})

    useEffect(() => {
        let temp = {}
        entry?.data?.aadhaarData?.forEach(element => {
            temp[element.name] = element.value
        })
        setData(temp)
        return () => {
            setData({})
        }
    }, [entry])
    console.log('data', data)

    return (<Grid>
        <Grid item xs={12}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
            <Typography variant='h2'>Guest Details
                &nbsp;
                <Chip variant='outlined' label={camelCaseToString(entry.state)} color={state[entry.state]} />
            </Typography>
            <BrandLogo />
        </Grid>
        <Divider fullWidth variant='dark' sx={{ margin: '1em 0 0 0' }} />


        {typeof data === 'object' ?
            <>
                <Grid item xs={12}>
                    <Typography sx={{ mt: 1 }} variant='h3'>Personal Details</Typography>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center"
                        sx={{ padding: '1em 0' }}>

                        <Card variant='outlined' sx={{ padding: '1em', margin: '1em 0', width: '100%' }}>
                            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item xs={12} sm={12} md={3} lg={3}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '1em'
                                    }}>
                                    <img
                                        alt="visitorPic"
                                        src={entry?.data?.preCheckinImageUrl ? getImageData(entry?.data?.preCheckinImageUrl) :
                                            noImageAvailable
                                        }
                                        style={{
                                            borderRadius: "1.2rem",
                                            width: '100%',
                                            // height: "9.5rem", width: "9.5rem",
                                            objectFit: "cover"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={9} lg={9} sx={{ padding: '1em' }}>

                                    {mainTitles.map((item, i) => <Grid
                                        container direction="row" justifyContent="flex-start" alignItems="flex-start">

                                        <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: 'left' }}>
                                            <Typography variant="body1" sx={{ color: '#666666' }}>{item.name}</Typography>
                                        </Grid>

                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                            <Typography variant="body1">:</Typography>
                                        </Grid>

                                        <Grid item xs={7} sm={7} md={7} lg={7} sx={{ textAlign: 'left' }}>
                                            <Typography variant="body1">{item.value ? item.value(entry) : data[item.key]}</Typography>
                                        </Grid>
                                    </Grid>)}



                                </Grid>
                                <Grid xs={12} sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                }}>
                                    <img src={aadhaarLogo} alt="aadhaar" style={{ width: '50px' }} />
                                    <img src={digilockerVerified} alt="aadhaar" style={{ width: '100px' }} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h3'>Reservation Details</Typography>
                        <Card variant='outlined' sx={{ padding: '1em', margin: '1em 0' }}>
                            <Grid
                                container direction="row" justifyContent="space-between" alignItems="flex-start">
                                {sampleData.map((item, i) => <Grid key={"sample-" + i}
                                    item xs={12} sm={12} md={i % 2 === 0 ? 6 : 5} lg={i % 2 === 0 ? 5 : 6} sx={{
                                        textAlign: 'left',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start'
                                    }}
                                >

                                    <Grid item xs={5} sm={5} md={5} lg={5} sx={{ textAlign: 'left' }}>
                                        <Typography variant="body1" sx={{ color: '#666666' }}>{item.name}</Typography>
                                    </Grid>

                                    <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <Typography variant="body1">:</Typography>
                                    </Grid>

                                    <Grid item xs={6} sm={6} md={6} lg={6} sx={{ textAlign: 'left' }}>
                                        <Typography variant="body1">{item.value ? item.value(entry) : data[item.key]}</Typography>
                                    </Grid>
                                </Grid>)}
                            </Grid>
                        </Card>
                    </Grid>

                    <Accordion sx={{ width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h3'>More Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid
                                container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                {
                                    Object.keys(data).map((x, i) => ['Name', 'Address']
                                        .includes(x) ? null :
                                        <Grid key={data[x] + i}
                                            item xs={12} sm={12} md={4} lg={4} sx={{ textAlign: 'left' }}>
                                            {x == 'Photo' || x == 'photo' ? <>
                                                <ListItemText
                                                    primary={camelCaseToString(x)}
                                                />
                                                <img
                                                    alt="visitorPic"
                                                    src={data[x] ? getImageData(data[x]) :
                                                        noImageAvailable
                                                    }
                                                    style={{
                                                        borderRadius: "1.2rem",
                                                        height: '20vh',
                                                        // height: "9.5rem", width: "9.5rem",
                                                        objectFit: "cover"
                                                    }}
                                                />
                                            </> : <ListItemText
                                                primary={camelCaseToString(x)}
                                                secondary={(data[x])} />}
                                        </Grid>)
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </> :
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '69vh',
                // width: '100%'
            }}>
                {data === 'loading' ? <CircularProgress /> : <Nodata size="25vh" />}

            </div>
        }

    </Grid >
    );
}

const mainTitles = [
    { name: 'Guest Name', key: 'Name' },
    { name: 'Identifier Number', value: (x) => x.identifier },
    { name: 'Address', key: 'Address' },
    { name: 'Contact Number', value: (x) => x.mobile },

]

const sampleData = [
    { name: 'Booking Date & Time', value: (x) => displayTime(x.createdAt, "LLLL") },
    { name: 'Room Rate', value: () => "₹25,970 per night" },
    { name: 'Scheduled Check In Date', value: (x) => addTime(x.createdAt, [4, 'd']) },
    { name: 'Checked In At', value: (x) => x?.in?.time ? displayTime(x?.in?.time, "LLLL") : '' },
    { name: 'Scheduled Check Out Date', value: (x) => addTime(x.createdAt, [8, 'd']) },
    { name: 'Checked Out At', value: (x) => x?.out?.time ? displayTime(x?.out?.time, "LLLL") : '' },
    { name: 'Stay Duration', value: (x) => "4 Nights" },
    { name: 'Accomodation Detail', value: (x) => "All In Getaway" },
    { name: 'Room Type', value: (x) => "ITC One Doubles" },
    { name: 'Payment Type', value: (x) => "CC" },
]