import { CheckCircle, Error } from '@mui/icons-material';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect } from 'react';
import WebcamCapture from '../../Components/Webcam';
import { checkInVisitor, compareImageSimilarity } from './Store/actions';

export default function CheckIn({ imageToMatchWith, image, setImage, status, setStatus, id, onSuccess, webCamCaptureRef,
    verifyButtonRef }) {

    const [qrCode, setQrCode] = React.useState("")

    const [message, setMessage] = React.useState("")


    // const sse = useSSE({
    //     // page: config.pages[company]?.credentialInviteId,
    //     // checkIfQRClosedManually: checkIfQRClosedManually,
    //     // setCheckIfQRClosedManually: setCheckIfQRClosedManually,
    //     // setInvitationUrl: setInvitationUrl,
    //     setOpenQrPopup: setOpenCheckInDialog,
    //     // openQrPopup: openQrPopup,
    //     // setLatestSseResponse: setLatestSseResponse,
    //     // setUuid: setUuid,
    //     // disableQrDialogOnThisState: flowProgress.disableQrDialogOnThisState,
    //     onFinish: () => {
    //     }
    // })

    const checkLivliness = async () => {
        setStatus('LOADING')
        const res = await compareImageSimilarity(imageToMatchWith, image?.split('base64,')[1]);

        if (!res.error) {
            if (res?.data > 70) {
                setStatus('VERIFIED')
                return true;
            } else {
                setStatus('FAILED')
                setMessage('Face match failed')
                return false
            }
        } else {
            setStatus('FAILED')
            return false
        }
    }

    useEffect(() => {
        return () => {
            onSuccess()
            setQrCode("")
            setStatus("")
            setImage("")
        }
    }, [])


    return (
        <Grid container direction="column" justifyContent="center" alignItems="center"
            sx={{ flexWrap: 'nowrap', height: '100%', minHeight: '80vh' }}>

            {
                status === 'FAILED' ? <>
                    <Typography variant='h6'>{message}</Typography>
                    <Typography variant='body1'>Please try again!</Typography>
                    <Error sx={{ fontSize: '10vh' }} color='error' />
                    <Button
                        variant='contained'
                        color="primary"
                        sx={{ padding: '1em 2em', fontSize: '1em' }}
                        onClick={() => {
                            // sse.establishSSE();
                            setStatus('')
                            setImage("")
                        }}>Try Again</Button>
                </> : null
            }

            {
                status === 'VERIFIED' ? <Grid sx={{ height: window.innerHeight * 0.6 }}
                    container direction="column" justifyContent="center" alignItems="center">
                    <Typography variant='h6'>Visitor Verified 🎉</Typography>
                    <CheckCircle sx={{ fontSize: '10vh' }} color='success' />
                    <Button
                        variant='contained'
                        color="secondary"
                        sx={{ padding: '1em 2em', fontSize: '1em' }}
                        onClick={async () => {
                            // sse.establishSSE();
                            setStatus('SHOWQR')
                            setImage("")
                        }}>Complete Check In</Button>
                </Grid> : null
            }

            {status === "LOADING" ? <CircularProgress /> : null}

            {
                (status === "") ? (
                    <>{image ? <img src={image} alt="webcamed"
                        style={{ width: '60vh' }} /> :

                        <WebcamCapture
                            enable={!image}
                            setImage={setImage}
                            buttonRef={webCamCaptureRef} />}

                        {image ? <Button
                            ref={verifyButtonRef}
                            variant='contained'
                            color="secondary"
                            sx={{ padding: '1em 2em', fontSize: '1.2em', display: 'none' }}
                            onClick={() => {
                                checkLivliness().then(async (response) => {
                                    if (response) {
                                        const res = await checkInVisitor(id)
                                        if (!res.error) {
                                            setQrCode(res.data)
                                        }
                                    }
                                })
                            }}>Verify</Button> : null}</>
                ) : null
            }

            {status === "SHOWQR" ? (
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Typography variant='h6'>Please scan the QR to complete check in</Typography>
                    <QRCodeSVG
                        value={qrCode}
                        size={window.innerHeight * 0.6}
                        level={"L"}
                        includeMargin={true}
                        fgColor="#000000"
                    />
                </Grid>
            ) : null}

        </Grid>
    )
}


