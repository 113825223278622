import axios from 'axios'
const qs = require("qs");
const jwt = require("jsonwebtoken");

export const getAccessToken = () => {
  return localStorage.getItem("token");
};

export const decodeAccessToken = (token) => {
  // eslint-disable-next-line max-len
  // const keycloakPem = `-----BEGIN CERTIFICATE-----${process.env.REACT_APP_KEYCLOAK_PUBLIC_CERT}-----END CERTIFICATE-----`;
  const keycloakPem = Buffer.from(
    process.env.REACT_APP_KEYCLOAK_PUBLIC_CERT.toString(),
    "base64"
  );

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line space-before-function-paren
    jwt.verify(token, keycloakPem, function (error, decoded) {
      if (error) {
        reject(error);
      } else {
        resolve(decoded);
      }
    });
  });
};

export const generateLoginToken = async (email, password) => {
  const data = qs.stringify({
    username: email,
    grant_type: "password",
    password: password,
    client_id: "indisi-users",
  });

  const config = {
    method: "post",
    url: process.env.REACT_APP_AUTH_TOKEN_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    if (!response?.response) {
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("refreshToken", response.data.refresh_token);
      localStorage.setItem("authRes", JSON.stringify(response.data));
      refreshAccessTokenAtRegularInterval();
    }
    return response;
  } catch (e) {
    return e;
  }
};

export const generateLoginTokenFromRefreshToken = async (refreshToken) => {
  const data = qs.stringify({
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    client_id: "indisi-users",
  });
  const config = {
    method: "post",
    url: process.env.REACT_APP_AUTH_TOKEN_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    localStorage.setItem("refreshToken", response.data.refresh_token);
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("authRes", JSON.stringify(response.data));
    return response;
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("agentToken");
    localStorage.removeItem("role");
    localStorage.removeItem("userLevel");
    localStorage.removeItem("refreshToken");
    return {};
  }
};

window.intervalId = null;
export const refreshAccessTokenAtRegularInterval = async () => {
  try {
    window.intervalId = setInterval(async () => {
      const authRes = JSON.parse(localStorage.getItem("authRes"));
      if (authRes?.refresh_token) {
        await generateLoginTokenFromRefreshToken(authRes.refresh_token);
      }
    }, 60000);
  } catch (e) {
    console.error(e);
  }
};

export const startRefreshAccessTokenAtRegularInterval = async () => {
  const authRes = JSON.parse(localStorage.getItem("authRes"));

  if (authRes?.refresh_token) {
    const res = await generateLoginTokenFromRefreshToken(authRes.refresh_token);
    if (res.status === 200) {
      refreshAccessTokenAtRegularInterval();
      return true;
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("agentToken");
      localStorage.removeItem("role");
      localStorage.removeItem("userLevel");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("authRes");
      return false;
      // window.open('/user/login', '_self');
    }
  }
};
