import { CameraAlt, Cameraswitch, CameraswitchOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ENTRIES_UPDATE_STATE } from "../../Containers/Entries/Store/actionTypes";
import { Camera } from "react-camera-pro";



const WebcamCapture = ({ setImage, buttonRef }) => {
    const dispatch = useDispatch()
    const webcamFacingMode = useSelector(x => x.entries.webcamFacingMode) ?? "user"
    const videoConstraints = {
        width: 720,
        height: 720,
        facingMode: 'user'
    };
    const webcamRef = React.useRef(null);
    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.takePhoto();
            setImage(imageSrc);
        },
        [setImage, webcamRef]
    );
    console.log(webcamFacingMode)
    return (
        <>
            {/* <Webcam
                audio={false}
                height={window.innerHeight * 0.6}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                screenshotQuality={1}
                onUserMediaError={(e) => {
                    console.log('cam error', e)
                    window.snackbar(e.message)
                }}
            /> */}

            <Camera ref={webcamRef}
                facingMode={webcamFacingMode} />

            <Button
                ref={buttonRef}
                variant='outlined'
                color="secondary"
                sx={{
                    display: 'none',
                    padding: '1em 2em',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
                onClick={() => {
                    capture()
                }}>
                <CameraAlt />
                &nbsp;
                Capture
            </Button>

            <Button
                variant='outlined'
                color="secondary"
                sx={{
                    padding: '1em 2em',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '2em',
                    right: '2em'
                }}
                onClick={() => {
                    dispatch({
                        type: ENTRIES_UPDATE_STATE,
                        data: {
                            webcamFacingMode: webcamFacingMode === 'user' ? 'environment' : 'user',
                        },
                    });
                    webcamRef.current.switchCamera()
                }}>
                <CameraswitchOutlined />
                &nbsp;
                Flip Camera
            </Button>

        </>
    );
};

export default WebcamCapture