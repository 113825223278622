// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bullet: {
    backgroundColor: "background.first",
    marginTop: "-.5rem",
    border: ".1rem solid border.main",
    boxShadow: "0 3px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: ".4rem",
    padding: ".4rem",
    display: "grid",
    placeItems: "center"
  },
  outerBox: {
    backgroundColor: "background.first",
    border: ".1rem solid #DADADA",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: ".8rem",
    overflow: "hidden",
    position: "relative",
    height: "100%",
    display: 'flex',
    flexDirection: 'column'
  },
  topBox: {
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    position: "sticky",
    zIndex: "5",
    backgroundColor: "#fff",
    padding: "2rem",
  },
  middleBox: {
    overflow: "auto",
    padding: "0",
    // borderTop: ".1rem solid #DADADA",
    // borderBottom: ".1rem solid #DADADA",
    overflowX: "hidden",
    width: '100%',
    flex: 1
  },
  children: {
    paddingLeft: "2.4rem",
    marginLeft: "1rem",
    display: "flex",
    flexDirection: "column",
    rowGap: "1.5rem",
    paddingTop: "1.5rem",
    borderLeft: ".1rem solid #DADADA",
  },
  bottomBox: {
    position: "sticky",
    bottom: '5px',
    zIndex: "5",
    backgroundColor: "background.first",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  innerBottomBox: {
    backgroundColor: "primary.main",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: ".8rem",
    width: "89%",
    padding: "4% 7.5%",
    cursor: "pointer",
    userSelect: "none",
    zIndex: "51",
  },
  issuerPopupRow: {
    width: "100%",
    "display": "flex",
    "alignItems": "center",
    justifyContent: "space-between",
    "color": "#fff",
    "cursor": "pointer",
    "userSelect": "none",
    "&:hover": {
      opacity: ".7"
    }
  },
  popupIcon: {
    height: "1.58rem",
    width: "1.58rem"
  }

};

