import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress, Typography } from "@mui/material";
import PillButton from "../../../Components/Buttons/PillButton";
import StandardTextfield from "../../../Components/Input/TextField";
import authActions from "../store/actions";
import { deleteFromErrors } from "../../../Helpers/helpers";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";

function ResetPassword({}) {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.alert.loading);
  const [errors, setErrors] = useState({});
  const [loginInfo, setLoginInfo] = useState({ password: "", confirmPassword: ""});
  const { password, confirmPassword } =loginInfo;
  const [visibility, setVisibility] = useState(false);
  const [confirmVisibility, setConfirmVisibility] = useState(false);
  const history = useHistory();
  const [matchPassword, setMatchPassword] = useState(false);

  const handleChange = (name, value) => {
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) deleteFromErrors(errors, setErrors, name);
  };

  const handleSubmit = () => {
    const tempErrors = { ...errors };
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const lowerCase = /[a-z]/;
    const upperCase = /[A-Z]/;
    const numbers = /[0-9]/;
    Object.keys(loginInfo).forEach((field) => {
      if (loginInfo[field] === "") {
        tempErrors[field] = "required";
      } else if (loginInfo[field].length < 8) {
        tempErrors[field] = "Minimum 8 characters required";
      } else if (!specialChars.test(loginInfo[field])) {
        tempErrors[field] = "Atleast one special character required";
      } else if (!lowerCase.test(loginInfo[field])) {
        tempErrors[field] = "Atleast one lowercase character required";
      } else if (!upperCase.test(loginInfo[field])) {
        tempErrors[field] = "Atleast one uppercase character required";
      } else if (!numbers.test(loginInfo[field])) {
        tempErrors[field] = "Atleast one number required";
      }
    });
    if (Object.keys(tempErrors).length > 0) return setErrors(tempErrors);
    if (password !== confirmPassword) {
      setMatchPassword(true);
      return;
    } else {
      setMatchPassword(false);
    }
    dispatch(authActions.resetPassword(token, password, confirmPassword, history));
  };

  const handleEnterKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) handleSubmit();
  };

  return (
    <>
      <Typography variant="h3" sx={{ textAlign: "center" }}>Reset Password to your account</Typography>
      <Typography variant="caption" sx={{ color: "rgb(112, 112, 112)", textAlign: "center" }}>
                Enter a new password
      </Typography>

      <StandardTextfield
        label="Password"
        value={password}
        onChange={(e) => handleChange("password", e.target.value)}
        sx={{ width: "100%", marginBottom: ".5rem" }}
        endIcon={visibility ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
        endIconOnClick={() => setVisibility((prev) => !prev)}
        errorBool={errors?.password?.length > 0}
        type={visibility ? "text" : "password"}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
        helperText={errors["password"]}
      />

      <StandardTextfield
        label="Confirm Password"
        value={confirmPassword}
        onChange={(e) => handleChange("confirmPassword", e.target.value)}
        sx={{ width: "100%", marginBottom: ".5rem" }}
        endIcon={confirmVisibility ? <VisibilityIcon sx={{ height: "2rem", width: "2rem" }} /> : <VisibilityOffIcon sx={{ height: "2rem", width: "2rem" }} />}
        endIconOnClick={() => setConfirmVisibility((prev) => !prev)}
        errorBool={errors?.confirmPassword?.length > 0}
        type={confirmVisibility ? "text" : "password"}
        onKeyDown={handleEnterKeyPress}
        shrinkLabel
        helperText={errors["confirmPassword"]}
      />
      {matchPassword &&
        <Typography variant="caption" sx={{ color: "error.main", textAlign: "center" }}>
                Password and Confirm Password must match
        </Typography>
      }

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption" sx={{ cursor: "pointer", width: "fit-content", mt: 1 }} onClick={() => history.push("/login")}>Back to Login?</Typography>
        <PillButton
          onClick={handleSubmit}
          text={isLoading ?
            <CircularProgress size="2rem" sx={{ color: "white" }} /> :
            "Submit"
          } padding={{ horizontal: isLoading ? "5rem" : "3.8rem", vertical: isLoading ? ".6rem" : ".8rem" }} />
      </Box>
    </>
  );
}

export default ResetPassword;
